export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~5],
		"/(marketing)/about": [18,[4]],
		"/(logged-in)/account/clients": [~9,[3]],
		"/(logged-in)/account/settings": [~10,[3]],
		"/(logged-in)/account/update-password": [~11,[3]],
		"/(marketing)/book-demo": [19,[4]],
		"/(logged-in)/contacts": [~12,[3]],
		"/(logged-in)/contacts/[contactId]": [~13,[3]],
		"/(marketing)/contact": [20,[4]],
		"/(logged-in)/documents": [~14,[3]],
		"/(logged-in)/documents/[documentId]": [~15,[3]],
		"/(authentication)/forgot-password": [~6,[2]],
		"/(marketing)/learn-more": [21,[4]],
		"/(authentication)/login": [~7,[2]],
		"/(logged-in)/new": [16,[3]],
		"/(logged-in)/onboarding": [~17,[3]],
		"/(marketing)/pricing-old": [23,[4]],
		"/(marketing)/pricing": [22,[4]],
		"/(marketing)/privacy": [24,[4]],
		"/(authentication)/register": [~8,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';